import { render, staticRenderFns } from "./ReservationNotificationSettingEdit.vue?vue&type=template&id=02c2127c&scoped=true"
import script from "./ReservationNotificationSettingEdit.vue?vue&type=script&lang=js"
export * from "./ReservationNotificationSettingEdit.vue?vue&type=script&lang=js"
import style0 from "./ReservationNotificationSettingEdit.vue?vue&type=style&index=0&id=02c2127c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02c2127c",
  null
  
)

export default component.exports