<template>
  <div class="LySlider">
    <div class="lyInner" style="margin-bottom: 12px;">
      <div class="lyItem LyMe">
        <div class="T1 fxLTR" dir="ltr">
          <div class="t1Body">
            <div class="MdBx vr" style="color: #3B3324; font-size: 15px;text-align: center; padding-bottom: 78px;">
              (未套用範本)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreviewNoTemplateBlock',
  setup (props) {
    return {
    }
  },
})
</script>
