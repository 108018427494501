export const sendTimeType = {
  now: { label: '當下', value: 'now' },
  before: { label: '前', value: 'before' },
  after: { label: '後', value: 'after' },
}
export const sendTimeUnit = {
  hour: { label: '小時', value: 'hour' },
  day: { label: '天', value: 'day' },
}
export const sendTimeOffsetSpecified = [
  '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
]
export const sendType = {
  appointmentCreate: { label: '建立預約', value: 'appointmentCreate' },
  appointmentChange: { label: '變更預約', value: 'appointmentChange' },
  appointmentCancel: { label: '取消預約', value: 'appointmentCancel' },
  appointmentStart: { label: '預約開始', value: 'appointmentStart' },
  appointmentEnd: { label: '預約結束', value: 'appointmentEnd' },
}
