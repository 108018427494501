<template>
  <GrayBlockContainer>
    <div>
      <TitleWithSwitch :toggle.sync="syncToggle" :inactiveText="inactiveText">
        <template #title>
          <p class="text-primary-100 font-bold text-normal" style="line-height: 23.17px;">{{ title }}</p>
        </template>
      </TitleWithSwitch>

      <div class="flex items-center">
        <p>套用通知內容範本</p>
        <TipInfo v-if="!hideTooltip" width="200">
          <slot name="tooltip" />
        </TipInfo>
      </div>

      <el-form-item :prop="`NotifySettings[${notifyIndex}].contents[${contentIndex}].NotifyContentTemplateId`">
        <ElInputWrapper>
          <el-select
            v-model="syncTemplate"
            style="width: 100%"
            :disabled="!syncToggle"
          >
            <el-option
              v-for="option in contentTemplateOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </el-select>
        </ElInputWrapper>
      </el-form-item>
    </div>
  </GrayBlockContainer>
</template>

<script>
import { defineComponent, ref } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TitleWithSwitch from '@/components/TitleWithSwitch.vue'
import TipInfo from '@/components/TipInfo.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ToggleNotificationContentBlock',
  components: { GrayBlockContainer, TitleWithSwitch, TipInfo, ElInputWrapper },
  props: {
    title: String,
    formItemProp: String,
    inactiveText: String,
    notifyIndex: Number,
    contentIndex: Number,
    enabled: Boolean,
    contentTemplate: String,
    contentTemplateOptions: { type: Array, default: () => [] },
    hideTooltip: Boolean,
  },
  setup (props, { emit }) {
    const test = ref(null)
    const syncToggle = useVModel(props, 'enabled', emit)
    const syncTemplate = useVModel(props, 'contentTemplate', emit)

    return { test, syncToggle, syncTemplate }
  },
})
</script>

<style lang="postcss" scoped>

</style>
