<template>
  <el-form-item label="接收通知附加條件">
    <el-select v-model="syncValue">
      <el-option
        v-for="option in targetConditionTypeConfigs"
        :key="option.value"
        :value="option.value"
        :label="option.label"
      />
    </el-select>
  </el-form-item>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import { targetConditionTypeConfigs } from '@/config/progressNotification'

export default defineComponent({
  name: 'NotificationAttachConditionBlock',
  props: {
    value: String,
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    return { syncValue, targetConditionTypeConfigs }
  },
})
</script>

<style lang="postcss" scoped>

</style>
