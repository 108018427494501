<template>
  <ElInputWrapper class="el-input-group" :class="`grid-cols-${column}`" :style="`gap: ${gap}px`">
    <slot />
  </ElInputWrapper>
</template>

<script>
import { defineComponent } from 'vue'
import ElInputWrapper from './ElInputWrapper.vue'

export default defineComponent({
  name: 'ElInputGroup',
  components: { ElInputWrapper },
  props: {
    gap: { type: [String, Number], default: 8 },
    column: { type: [String, Number], default: 2 },
  },
  setup (props, { emit }) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.el-input-group {
  @apply grid  items-center;
}
</style>
