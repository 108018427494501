<template>
  <div class="notify-content-preview-block">
    <div v-if="loading" v-loading="loading" class="preview-loading" />
    <div v-if="!loading" class="chatbox">
      <div v-if="perviewError" class="flex justify-center">
        <p class="error-msg">提示：部分顯示欄位尚未設定資訊</p>
      </div>
      <div v-for="(message, idx) in lineMessagesData" :id="`message-${notifyIndex}-${idx}`" :key="`message-${notifyIndex}-${idx}`">
        <PreviewNoTemplateBlock v-if="get(message, 'content.NotifyContentTemplate.isRemove')" />
        <template v-else>
          <div
            v-for="(lineMessage, index) in message.lineMessages"
            :id="`message-${notifyIndex}-${idx}-${index}`"
            :key="`message-${notifyIndex}-${idx}-${index}`"
          >
            <PreviewTextBlock v-if="lineMessage.type === 'text'" :text="lineMessage.text" />
            <PreviewImageBlock v-if="lineMessage.type === 'image'" :url="lineMessage.previewImageUrl" />
          </div>
        </template>
        <PreviewHiddenBlock v-if="message.type === 'hidden'" />
      </div>
      <!-- 補充說明 -->
      <PreviewInfo />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch, ref, computed, nextTick } from 'vue'
import { PreviewProgressNotificationSettings } from '@/api/progressNotification'
// import { useFetch } from '@/use/fetch'
import { useDebounceFn } from '@vueuse/core'
import { useShop } from '@/use/shop'
import { flex2html } from '@/utils/flex2html'
import { passValue } from '@/utils/helper'
import { get, filter, map, includes } from 'lodash'
import PreviewInfo from './PreviewInfo.vue'
import PreviewTextBlock from './PreviewTextBlock.vue'
import PreviewImageBlock from './PreviewImageBlock.vue'
import PreviewHiddenBlock from './PreviewHiddenBlock.vue'
import PreviewNoTemplateBlock from './PreviewNoTemplateBlock.vue'
import { reservationNotificationSettingTypeConfigs } from '@/config/progressNotification'

export default defineComponent({
  name: 'NotifyContentPreviewBlock',
  components: {
    PreviewInfo,
    PreviewTextBlock,
    PreviewImageBlock,
    PreviewHiddenBlock,
    PreviewNoTemplateBlock,
  },
  props: {
    contents: { type: Array, default: () => [] },
    notifyIndex: { type: Number, default: 0 },
    notifyType: String,
  },
  setup (props) {
    // const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const loading = ref(false)
    const lineMessagesData = ref([])
    const perviewError = ref(false)
    watch(props, async () => {
      console.log('props change')
      await getPreviewData()
    })

    const filterContent = computed(() => {
      const data = passValue(get(props, 'contents'))

      return map(data, content => {
        if (content.appointmentInfoConfig) {
          content.appointmentInfoConfig.actions = map(content.appointmentInfoConfig.actions, (action) => ({
            ...action,
            linkUrl: action.linkUrl !== undefined && !action.linkUrl ? 'w' : action.linkUrl,
            label: action.label !== undefined && !action.label ? '' : action.label,
          }))
        }
        return content
      })
    })

    const getPreviewData = useDebounceFn(async () => {
      if (loading.value) return
      loading.value = true

      // 如果有其中一個區塊沒有選擇範本 -> 不打 api
      const nullNotifyContentTemplateId = filter(get(props, 'contents'), (content) => {
        if (includes([
          reservationNotificationSettingTypeConfigs.appointmentCancel.value,
          reservationNotificationSettingTypeConfigs.appointmentEnd.value,
          reservationNotificationSettingTypeConfigs.appointmentStart.value,
        ], props.notifyType)) return false
        return get(content, 'NotifyContentTemplateId') === null
      })
      if (nullNotifyContentTemplateId.length > 0) {
        perviewError.value = true
        loading.value = false
        return
      }
      // await simpleFetch(PreviewProgressNotificationSettings, {
      //   shopId: shopId.value,
      //   contents: filterContent.value,
      // }, async (res) => {
      //   lineMessagesData.value = res.result
      //   try {
      //     loading.value = false
      //     await nextTick()
      //     let count = 0
      //     for (const message of res.result) {
      //       if (message.type === 'lineMessages') {
      //         let lineCount = 0
      //         for (const lineMessage of message.lineMessages) {
      //           if (lineMessage.type === 'flex') flex2html(`message-${props.notifyIndex}-${count}-${lineCount}`, lineMessage)
      //           lineCount += 1
      //         }
      //       }
      //       count += 1
      //     }
      //   } catch (error) {
      //     console.log(error)
      //   }
      // }, false, false)
      const [res, err] = await PreviewProgressNotificationSettings({
        shopId: shopId.value,
        contents: filterContent.value,
      })
      if (err) {
        // console.log('錯誤', err)
        perviewError.value = true
        loading.value = false
        return
      }
      try {
        lineMessagesData.value = res.result
        loading.value = false
        perviewError.value = false
        await nextTick()
        let count = 0
        for (const message of res.result) {
          if (message.type === 'lineMessages') {
            let lineCount = 0
            for (const lineMessage of message.lineMessages) {
              if (lineMessage.type === 'flex') flex2html(`message-${props.notifyIndex}-${count}-${lineCount}`, lineMessage)
              lineCount += 1
            }
          }
          count += 1
        }
      } catch (error) {
        console.log(error)
      }
    }, 300)

    onMounted(async () => {
      await getPreviewData()
    })
    return {
      getPreviewData,
      loading,
      lineMessagesData,
      get,
      perviewError,
    }
  },
})
</script>

<style lang="postcss" scoped>
@import './flex2html.css';

.notify-content-preview-block {
  @apply h-full w-full bg-[#849ebf];
}

.preview-loading {
  @apply flex-1 bg-[#849ebf] w-full h-full;
}

.alert-area {
  @apply bg-white w-[300px] p-[20px] m-auto rounded-[17px] mb-[20px];
}
.error-msg {
  @apply text-sm text-white bg-[#00000080] rounded-[8px] px-[8px] py-[3.5px] mb-[12px]
}
</style>
@/utils/flex2html2
