<template>
  <div
    class="
          LySlider"
  >
    <div class="lyInner">
      <div class="lyItem LyMe">
        <div class="info">
          補充說明：<br>
          <ul>
            <li>請以「顯示」狀態下的樣式預覽為準</li>
            <li>一則 Line 通知包含五則訊息內容 (以上方樣式預覽為例，一張卡片為一則訊息內容)，超過便會扣除第二則通知的額度。需留意預設通知與限定對象通知數量的加總</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreviewInfo',
  setup (props) {
    return {
    }
  },
})
</script>

<style lang="postcss" scoped>
.info {
  @apply text-sm text-white mt-[12px] mb-[20px]
}
.info ul {
  @apply pl-[16px] list-disc
}
</style>
