import { computed } from 'vue'
import { map, get, filter, find, flatten } from 'lodash'

export const useProgressNotificationTarget = ({reservationServicesData}) => {
  const categoryServiceDataList = computed(() => {
    return map(get(reservationServicesData.value, 'appointmentCategories', []), category => ({
      name: category.name,
      id: category.id,
      type: 'category',
      services: map(get(category, 'AppointmentServices'), service => ({
        category: category.id,
        name: service.name,
        id: service.id,
        type: 'service',
      })),
    }))
  })

  const serviceDataList = computed(() => {
    return map(get(reservationServicesData.value, 'appointmentServices', []), service => ({
      name: service.name,
      id: service.id,
      type: 'service',
      subServices: map(get(service, 'AppointmentSubServices'), subService => ({
        service: service.id,
        name: subService.name,
        id: subService.id,
        type: 'sub-service',
      })),
    }))
  })

  const attachServiceDataList = computed(() => {
    return map(get(reservationServicesData.value, 'appointmentServiceAttaches', []), service => ({
      name: service.name,
      id: service.id,
      // type: 'service',
    }))
  })

  const getDataByPath = (sourceData, dataPath) => {
    return flatten(map(sourceData, item => get(item, dataPath)))
  }

  return { categoryServiceDataList, serviceDataList, attachServiceDataList, getDataByPath }
}
