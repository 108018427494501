<template>
  <div class="target-notification-select-block">
    <div v-if="isDefault">
      <el-form-item label="所有服務項目均會收到此通知" :show-message="false">
        <p class="hint-text">如需發送不一樣的通知內容，給限定的對象，請返回通知列表頁，新增一則通知</p>
      </el-form-item>
    </div>

    <div v-else>
      <el-form-item label="限定的服務項目將收到此通知" prop="NotifyTargetTemplateId">
        <template slot="label">
          <div class="flex justify-between w-[560px]">
            <div>限定的服務項目將收到此通知</div>
            <div v-if="syncValue" class="text-sub text-gray-50 underline" @click="showDialog = true">查看範本包含的通知對象</div>
          </div>
        </template>
        <el-select v-model="syncValue" value-key="id">
          <el-option
            v-for="option in templateOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
      </el-form-item>
      <p class="hint-text">如需調整所有對象「均會」收到的通知，請調整「預設通知」</p>
    </div>

    <TargetNotificationSelectModal
      v-if="showDialog"
      :targetTemplate="selectedTemplate"
      :reservationServicesData="reservationServicesData"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, ref, computed, onMounted } from 'vue'
import { map, find } from 'lodash'
import TargetNotificationSelectModal from './TargetNotificationSelectModal.vue'
import { GetNotifyTargetTemplateAppointmentConfigOptions } from '@/api/progressNotification'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'TargetNotificationSelectBlock',
  components: { TargetNotificationSelectModal },
  props: {
    value: { type: String },
    targetTemplateList: { type: Array, default: () => [] },
    isDefault: { type: Boolean },
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const showDialog = ref(false)
    const reservationServicesData = ref({})
    const templateOptions = computed(() => {
      return map(props.targetTemplateList, template => ({
        id: template.id,
        label: template.name,
        value: template.id,
      }))
    })

    const selectedTemplate = computed(() => {
      return find(props.targetTemplateList, { id: syncValue.value })
    })

    onMounted(async () => {
      simpleFetch(GetNotifyTargetTemplateAppointmentConfigOptions, { shopId: shopId.value }, (res) => {
        console.log(res)
        reservationServicesData.value = res
      })
    })
    return { syncValue, showDialog, templateOptions, selectedTemplate, reservationServicesData }
  },
})
</script>

<style lang="postcss" scoped>
.target-notification-select-block {
    @apply bg-white rounded-[8px] p-[20px];
}

.hint-text {
    @apply text-sm text-gray-60;
}

::v-deep .el-form-item {
  @apply mb-0;
}
</style>
