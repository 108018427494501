<template>
  <div class="LySlider">
    <div class="lyInner">
      <div class="lyItem LyMe">
        <img :src="url" alt="image">
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreviewImageBlock',
  props: {
    url: { type: String, default: '' },
  },
  setup (props) {
    return {
    }
  },
})
</script>

<style lang="postcss" scoped>
.info {
  @apply text-sm text-white mt-[12px] mb-[20px]
}
.info ul {
  @apply pl-[16px] list-disc
}
</style>
