<template>
  <el-dialog
    :visible="true"
    v-bind="$attrs"
    title="範本包含的通知對象"
    width="566px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p class="text-gray-80 text-sm mb-[12px]">如需更動範本內的設定，請至「通知模組 > 通知範本設定 > 通知對象範本」</p>
    <GrayBlockContainer class="mb-[40px]">
      <p class="text-sub text-gray-100 font-medium mb-[8px]">通知對象範本A</p>
      <el-form label-position="left" label-width="90px">
        <el-form-item v-if="servicesList.length" label="服務項目">
          <div>{{ servicesList }}</div>
        </el-form-item>
        <el-form-item v-if="attachServicesList.length" label="附加服務">
          <div>{{ attachServicesList }}</div>
        </el-form-item>
      </el-form>
    </GrayBlockContainer>

    <div class="flex justify-end items-center">
      <el-button type="primary" @click="$emit('close')">確認</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, toRef } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useProgressNotificationTarget } from '@/use/useProgressNotificationTarget'
import { map, join, get, find } from 'lodash'

export default defineComponent({
  name: 'TargetNotificationSelectModal',
  components: {
    GrayBlockContainer,
  },
  props: {
    targetTemplate: { type: Object, default: () => ({}) },
    reservationServicesData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { categoryServiceDataList, serviceDataList, attachServiceDataList, getDataByPath } = useProgressNotificationTarget({ reservationServicesData: toRef(props, 'reservationServicesData') })

    const servicesList = computed(() => {
      const serviceSettings = get(props.targetTemplate, 'appointmentConfig.serviceSettings')
      const subServiceSettings = get(props.targetTemplate, 'appointmentConfig.subServiceSettings')

      const includeServices = map(get(serviceSettings, 'includeServiceIds'), serviceId => {
        return find(getDataByPath(categoryServiceDataList.value, 'services'), { id: serviceId }) || find(serviceDataList.value, { id: serviceId })
      }).filter(Boolean)
      const subServices = map(get(subServiceSettings, 'subServiceIds'), serviceId => {
        return find(getDataByPath(serviceDataList.value, 'subServices'), { id: serviceId })
      }).filter(Boolean)

      const result = [...includeServices, ...subServices]
      return map(result, item => item?.name).join('、')
    })
    const attachServicesList = computed(() => {
      const servicesData = get(props.reservationServicesData, 'appointmentServiceAttaches')
      return join(
        map(
          get(props.targetTemplate, 'appointmentConfig.serviceAttachSettings.serviceAttachIds'),
          (id) => {
            const target = find(servicesData, { id })
            return get(target, 'name')
          },
        ),
        '、')
    })
    return { servicesList, attachServicesList }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-dialog__body {
  @apply pt-0;
}
::v-deep .el-form-item {
  @apply mb-0 ;
}

::v-deep .el-form-item__label {
  @apply text-gray-60 text-sm font-medium float-left leading-[20.27px] pb-[4px];
}
::v-deep .el-form-item__content {
  @apply text-gray-60 text-sm leading-[20.27px];
}
</style>
