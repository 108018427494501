import { render, staticRenderFns } from "./PreviewImageBlock.vue?vue&type=template&id=23f0331e&scoped=true"
import script from "./PreviewImageBlock.vue?vue&type=script&lang=js"
export * from "./PreviewImageBlock.vue?vue&type=script&lang=js"
import style0 from "./PreviewImageBlock.vue?vue&type=style&index=0&id=23f0331e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f0331e",
  null
  
)

export default component.exports