<template>
  <div class="grid grid-cols-2 gap-[20px]">
    <el-form-item label="設定發送時間">
      <GrayBlockContainer>
        <div class="grid grid-flow-col items-center justify-start gap-[8px]">
          <p>{{ displayNotifyTypeLabel }}</p>
          <el-select v-model="syncValue.sendTimeType" @change="updateTimeType">
            <el-option
              v-for="option in filteredSendTimeType"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </el-select>

          <template v-if="syncValue.sendTimeType !== 'now'">
            <el-form-item :prop="`NotifySettings[${notifyIndex}].sendTimeSettings.sendTimeOffset`">
              <el-input v-model.number="syncValue.sendTimeOffset" @input="updateTimeOffset" />
            </el-form-item>

            <el-select v-model="syncValue.sendTimeUnit" @change="updateTimeUnit">
              <el-option
                v-for="option in sendTimeUnit"
                :key="option.value"
                :value="option.value"
                :label="option.label"
              />
            </el-select>
            <template v-if="syncValue.sendTimeUnit === 'day'">
              <p>的</p>
              <el-form-item :prop="`NotifySettings[${notifyIndex}].sendTimeSettings.sendTimeOffsetSpecified`">
                <el-select v-model="syncValue.sendTimeOffsetSpecified" class="input-time">
                  <el-option
                    v-for="option in sendTimeOffsetSpecified"
                    :key="option"
                    :value="option"
                    :label="option"
                  />
                </el-select>
              </el-form-item>
            </template>
          </template>
        </div>
        <div v-if="syncValue.sendTimeUnit === 'day' && syncValue.sendTimeOffset === 0" class="grid grid-flow-col items-center justify-start gap-[8px]">
          <p>對距離「通知發送時間」</p>
          <el-form-item :prop="`NotifySettings[${notifyIndex}].sendTimeSettings.limitScheduledMinHour`">
            <el-input v-model="syncValue.limitScheduledMinHour" placeholder="請輸入" />
          </el-form-item>
          <p class="text-sub">小時後的預約訂單發送通知</p>
          <TipInfo width="200" :size="16">
            系統將於設定的時間對「預約開始時間」介於當天{ 通知發送時間 + 時間範圍 } ~ 23:59:59 之間的訂單發送通知
          </TipInfo>
        </div>
      </GrayBlockContainer>
    </el-form-item>
  </div>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TipInfo from '@/components/TipInfo.vue'
import { defineComponent, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { get, set, omit } from 'lodash'
import { sendTimeType, sendTimeUnit, sendTimeOffsetSpecified, sendType } from '@/config/times'
import { useRoute } from 'vue-router/composables'
import { reservationNotificationSettingTypeConfigs } from '@/config/progressNotification'

export default defineComponent({
  name: 'SendTimeBlock',
  components: { GrayBlockContainer, TipInfo },
  props: {
    value: { type: Object, defualt: () => ({}) },
    notifyIndex: Number,
  },
  setup (props, { emit }) {
    const route = useRoute()
    const syncValue = useVModel(props, 'value', emit)
    const notifyType = computed(() => get(route.params, 'type'))

    const displayNotifyTypeLabel = computed(() => get(reservationNotificationSettingTypeConfigs, `${notifyType.value}.label`))
    const updateTimeType = () => {
      if (get(syncValue.value, 'sendTimeType') !== 'now' && !get(syncValue.value, 'sendTimeOffset') && !get(syncValue.value, 'sendTimeUnit')) {
        set(syncValue.value, 'sendTimeOffset', 1)
        set(syncValue.value, 'sendTimeUnit', 'hour')
      }
    }
    const updateTimeOffset = () => {
      if (get(syncValue.value, 'sendTimeOffset') === 0 && get(syncValue.value, 'sendTimeUnit') === 'day') {
        if (!get(syncValue.value, 'limitScheduledMinHour')) set(syncValue.value, 'limitScheduledMinHour', 0)
      }
    }
    const updateTimeUnit = () => {
      if (get(syncValue.value, 'sendTimeUnit') !== 'hour' && !get(syncValue.value, 'sendTimeOffsetSpecified')) {
        set(syncValue.value, 'sendTimeOffsetSpecified', '12:00')
      }
      updateTimeOffset()
    }
    const filteredSendTimeType = computed(() => {
      const omitList = get(reservationNotificationSettingTypeConfigs, `${notifyType.value}.omitList`)
      return omit(sendTimeType, omitList)
    })
    return { syncValue, sendTimeType, sendTimeUnit, sendTimeOffsetSpecified, notifyType, displayNotifyTypeLabel, updateTimeType, updateTimeOffset, updateTimeUnit, filteredSendTimeType }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-[80px];
}
::v-deep .input-time .el-input {
  @apply w-[100px];
}

::v-deep .el-form-item__error {
  @apply relative;
}
</style>
