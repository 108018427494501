<template>
  <div class="relative">
    <div v-if="!hideRemove" class="remove-setting-block-btn" @click="$emit('remove')">
      <SVGIcon icon="common-trash" fill="var(--gray-60)" />
      <p>刪除此通知內容</p>
    </div>
    <EnabledNotificationBlock :value.sync="syncForm.enabled" />
    <SendChannelOptionsBlock :value.sync="syncForm.channels" :notifyIndex="notifyIndex" />
    <NotificationAttachConditionBlock :value.sync="syncForm.targetCondition" />
    <SendTimeBlock :notifyIndex="notifyIndex" :value.sync="syncForm.sendTimeSettings" />
    <el-form-item required class="block-form-item">
      <template #label>
        <div class="flex items-center justify-between block" style="width: calc(50% - 10px)">
          <p>通知內容</p>
          <el-button type="text" class="underline text-primary-100" @click="hidePreview = !hidePreview">{{ hidePreview ? '顯示預覽' :'隱藏預覽' }}</el-button>
        </div>
      </template>
      <div class="grid grid-cols-2 gap-[20px]">
        <div v-if="!isDefault" class="flex flex-col gap-[20px]">
          <!-- 圖文資訊 -->
          <div>
            <p class="hint-text">「限定對象通知」僅能設置「圖文資訊」，如需變更「預約資訊、店家資訊」的設定，請至可發送給所有通知對象的「預設通知」。</p>
            <ToggleNotificationContentBlock
              v-if="syncForm.contents[0]"
              :title="contentBlockTypeConfigs.imageText.label"
              :notifyIndex="notifyIndex"
              :contentIndex="0"
              :inactiveText="`隱藏/顯示 ${contentBlockTypeConfigs.imageText.label}`"
              :enabled.sync="syncForm.contents[0].enabled"
              :contentTemplate.sync="syncForm.contents[0].NotifyContentTemplateId"
              :contentTemplateOptions="imageTextContentTemplateOptions"
            >
              <template #tooltip>如需新增通知內容範本，請至「通知模組 > 通知範本設定 > 通知內容範本」</template>
            </ToggleNotificationContentBlock>
          </div>
        </div>
        <div v-if="isDefault" class="flex flex-col gap-[20px]">
          <!-- 預約資訊 -->
          <ReservationInfoBlock
            v-if="contentTypes.includes('appointmentInfo')"
            :notifyIndex="notifyIndex"
            :contentIndex="0"
            :title="contentBlockTypeConfigs.appointmentInfo.label"
            :inactiveText="`隱藏/顯示 ${contentBlockTypeConfigs.appointmentInfo.label}`"
            :contentData.sync="appointmentInfoBlock"
            :contentTemplateOptions="appointmentInfoContentTemplateOptions"
          />
          <!-- 店家資訊 -->
          <ToggleNotificationContentBlock
            v-if="contentTypes.includes('shopInfo')"
            :title="contentBlockTypeConfigs.shopInfo.label"
            :notifyIndex="notifyIndex"
            :contentIndex="1"
            :inactiveText="`隱藏/顯示 ${contentBlockTypeConfigs.shopInfo.label}`"
            :enabled.sync="shopInfoEnabled"
            :contentTemplate.sync="shopInfoTemplateId"
            :contentTemplateOptions="shopInfoContentTemplateOptions"
          >
            <template #tooltip>如需新增通知內容範本，請至「通知模組 > 通知範本設定 > 通知內容範本」</template>
          </ToggleNotificationContentBlock>
          <!-- 圖文資訊 -->
          <ToggleNotificationContentBlock
            v-if="contentTypes.includes('imageText')"
            :title="contentBlockTypeConfigs.imageText.label"
            :notifyIndex="notifyIndex"
            :contentIndex="2"
            :inactiveText="`隱藏/顯示 ${contentBlockTypeConfigs.imageText.label}`"
            :enabled.sync="imageTextBlockEnabled"
            :contentTemplate.sync="imageTextBlockTemplateId"
            :contentTemplateOptions="imageTextContentTemplateOptions"
          >
            <template #tooltip>如需新增通知內容範本，請至「通知模組 > 通知範本設定 > 通知內容範本」</template>
          </ToggleNotificationContentBlock>
        </div>
        <div v-if="!hidePreview">
          <NotifyContentPreviewBlock
            :notifyType="notifyType"
            :contents="syncForm.contents"
            :notifyIndex="notifyIndex"
          />
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, set, watch } from 'vue'
import ToggleNotificationContentBlock from '../components/ToggleNotificationContentBlock.vue'
import SendChannelOptionsBlock from '../components/SendChannelOptionsBlock.vue'
import EnabledNotificationBlock from '../components/EnabledNotificationBlock.vue'
import NotificationAttachConditionBlock from '../components/NotificationAttachConditionBlock.vue'
import SendTimeBlock from '../components/SendTimeBlock.vue'
import ReservationInfoBlock from './components/ReservationInfoBlock.vue'
import NotifyContentPreviewBlock from '@/views/ProgressNotificationSettingEdit/components/NotifyContentPreviewBlock.vue'
import { useVModel } from '@vueuse/core'
import { useProgressNotificationEdit } from '@/use/useProgressNotificationEdit'
import { contentBlockTypeConfigs } from '@/config/progressNotification'
import { useRoute } from 'vue-router/composables'
import { map, get, filter, find } from 'lodash'

export default defineComponent({
  name: 'ReservationNotificationSettingEdit',
  components: {
    ToggleNotificationContentBlock,
    SendChannelOptionsBlock,
    EnabledNotificationBlock,
    NotificationAttachConditionBlock,
    ReservationInfoBlock,
    SendTimeBlock,
    NotifyContentPreviewBlock,
  },
  props: {
    isDefault: Boolean,
    notifyIndex: Number,
    form: { type: Object, default: () => ({}) },
    targetTemplateList: { type: Array, default: () => [] },
    contentTemplateList: { type: Array, default: () => [] },
    hideRemove: Boolean,
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const appointmentInfoBlock = computed({
      get: () => find(syncForm.value.contents, item => item.type === 'appointmentInfo'),
      set: (newValue) => {
        const index = syncForm.value.contents.findIndex(item => item.type === 'appointmentInfo')
        if (index !== -1) {
          syncForm.value.contents[index] = newValue
        }
      },
    })

    const createComputedForType = (type) => {
      return computed({
        get: () => get(find(syncForm.value.contents, item => item.type === type), 'NotifyContentTemplateId'),
        set: (newValue) => {
          const index = syncForm.value.contents.findIndex(item => item.type === type)
          if (index !== -1) {
            const target = find(syncForm.value.contents, item => item.type === type)
            target.NotifyContentTemplateId = newValue
            set(syncForm.value.contents, index, target)
          }
        },
      })
    }
    const createComputedEnabledForType = (type) => {
      return computed({
        get: () => get(find(syncForm.value.contents, item => item.type === type), 'enabled'),
        set: (newValue) => {
          const index = syncForm.value.contents.findIndex(item => item.type === type)
          if (index !== -1) {
            syncForm.value.contents[index].enabled = newValue
          }
        },
      })
    }
    const shopInfoTemplateId = createComputedForType('shopInfo')
    const shopInfoEnabled = createComputedEnabledForType('shopInfo')
    const imageTextBlockTemplateId = createComputedForType('imageText')
    const imageTextBlockEnabled = createComputedEnabledForType('imageText')

    const contentTypes = computed(() => map(syncForm.value.contents, 'type'))
    const route = useRoute()
    const hidePreview = ref(false)
    const { targetId, newContentData, filterContentTemplateOptions, selectDefaultContentTemplate } = useProgressNotificationEdit()
    const notifyType = computed(() => route.params.type)

    const appointmentInfoContentTemplateOptions = computed(() => filterContentTemplateOptions({
      contentType: contentBlockTypeConfigs.appointmentInfo.value,
      templateDataList: props.contentTemplateList,
    }))

    const shopInfoContentTemplateOptions = computed(() => filterContentTemplateOptions({
      contentType: contentBlockTypeConfigs.shopInfo.value,
      templateDataList: props.contentTemplateList,
    }))

    const imageTextContentTemplateOptions = computed(() => filterContentTemplateOptions({
      contentType: contentBlockTypeConfigs.imageText.value,
      templateDataList: props.contentTemplateList,
    }))

    onMounted(() => {
      if (!props.isDefault && !targetId.value) {
        // syncForm.value.contents.push(newContentData({ contentType: contentBlockTypeConfigs.appointmentInfo.value, order: 0, typeConfig }))
        // syncForm.value.contents.push(newContentData({ contentType: contentBlockTypeConfigs.shopInfo.value, order: 1 }))
        // syncForm.value.contents.push(newContentData({ contentType: contentBlockTypeConfigs.imageText.value, order: 2 }))
        if (!find(syncForm.value.contents, { type: 'imageText' })) {
          const newContent = newContentData({ contentType: contentBlockTypeConfigs.imageText.value, order: 0 })
          const template = selectDefaultContentTemplate({ templateDataList: props.contentTemplateList, contentType: 'imageText', notifyType: notifyType.value })
          newContent.NotifyContentTemplateId = template
          syncForm.value.contents.push(newContent)
        }
      }

      // for (const idx in syncForm.value.contents) {
      //   if (syncForm.value.contents[idx] && !syncForm.value.contents[idx].NotifyContentTemplateId) {
      //     syncForm.value.contents[idx].NotifyContentTemplateId = selectDefaultContentTemplate({
      //       templateDataList: props.contentTemplateList,
      //       contentType: syncForm.value.contents[idx].type,
      //     })
      //   }
      // }
    })

    return {
      targetId,
      syncForm,
      contentBlockTypeConfigs,
      appointmentInfoContentTemplateOptions,
      shopInfoContentTemplateOptions,
      imageTextContentTemplateOptions,
      appointmentInfoBlock,
      shopInfoTemplateId,
      shopInfoEnabled,
      imageTextBlockTemplateId,
      imageTextBlockEnabled,
      hidePreview,
      contentTypes,
      notifyType,
    }
  },
})
</script>

<style lang="postcss" scoped>
.remove-setting-block-btn {
  @apply absolute right-0 top-0;
  @apply flex items-center gap-[4px] w-[fit-content];
  @apply bg-gray-20 text-gray-80;
  @apply py-[3.5px] px-[8px] cursor-pointer rounded-[4px];
}
.remove-setting-block-btn:hover {
  @apply opacity-80;
}

.hint-text {
  @apply text-gray-60 leading-[17.38px] py-[20px] font-medium;
}

::v-deep .block-form-item .el-form-item__label {
  @apply block;
}
</style>
