<template>
  <GrayBlockContainer>
    <ElInputWrapper>
      <div>
        <TitleWithSwitch inactiveText="隱藏/顯示 預約資訊" :toggle.sync="syncData.enabled" @change="onBlockEnabledChange">
          <template #title>
            <p class="text-primary-100 font-bold text-normal" style="line-height: 23.17px; padding-bottom: 20px">預約資訊</p>
          </template>
        </TitleWithSwitch>
        <el-form-item v-if="syncData.appointmentInfoConfig" :prop="`NotifySettings[${notifyIndex}].contents[${contentIndex}].appointmentInfoConfig.title`">
          <TitleWithSwitch title="標題" inactiveText="隱藏/顯示" :toggle.sync="syncData.appointmentInfoConfig.enabledTitle" />
          <el-input v-model="syncData.appointmentInfoConfig.title" :disabled="!syncData.appointmentInfoConfig.enabledTitle || !syncData.enabled" clearable />
        </el-form-item>

        <!-- 範本選擇 -->
        <el-form-item :prop="`NotifySettings[${notifyIndex}].contents[${contentIndex}].NotifyContentTemplateId`">
          <template #label>
            <FormItemTooltipLabel label="其他內容：套用通知內容範本" :tooltipWidth="200">
              如需新增通知內容範本，請至「通知模組 > 通知範本設定 > 通知內容範本」
            </FormItemTooltipLabel>
          </template>
          <el-select v-model="syncData.NotifyContentTemplateId">
            <el-option
              v-for="option in contentTemplateOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </el-select>
        </el-form-item>

        <div v-if="syncData.appointmentInfoConfig" class="flex flex-col gap-[20px] pt-[20px]">
          <el-form-item v-for="(action, idx) in syncData.appointmentInfoConfig.actions" :key="idx">
            <div class="area">
              <TitleWithSwitch
                :title="action.style === 'small' ? '小按鈕' : '大按鈕'"
                inactiveText="隱藏/顯示"
                :toggle.sync="syncData.appointmentInfoConfig.actions[idx].enabled"
              />
              <ElInputGroup>
                <el-form-item :prop="`NotifySettings[${notifyIndex}].contents[${contentIndex}].appointmentInfoConfig.actions[${idx}].label`">
                  <el-input
                    v-model="syncData.appointmentInfoConfig.actions[idx].label"
                    placeholder="請輸入按鈕文字"
                    :disabled="!actionsData[idx].enabled || !syncData.enabled"
                    clearable
                    style="width: 100%;"
                  />
                </el-form-item>
                <el-form-item v-if="actionsData[idx].type === contentActionConfigs.openLink.value" :prop="`NotifySettings[${notifyIndex}].contents[${contentIndex}].appointmentInfoConfig.actions[${idx}].linkUrl`">
                  <el-input
                    v-model="syncData.appointmentInfoConfig.actions[idx].linkUrl"
                    :placeholder="contentActionConfigs.openLink.placeholder"
                    :disabled="!actionsData[idx].enabled || !syncData.enabled"
                    clearable
                    style="width: 100%;"
                  />
                </el-form-item>
                <el-input v-if="actionsData[idx].type === contentActionConfigs.openClientAppointmentOrder.value" :value="contentActionConfigs.openClientAppointmentOrder.placeholder" disabled />
                <el-input v-if="actionsData[idx].type === contentActionConfigs.addGoogleCalendar.value" :value="contentActionConfigs.addGoogleCalendar.placeholder" disabled />
                <el-input v-if="actionsData[idx].type === contentActionConfigs.confirmMessage.value" :value="contentActionConfigs.confirmMessage.placeholder" disabled />
              </ElInputGroup>
            </div>
          </el-form-item>
        </div>
      </div>
    </ElInputWrapper>
  </GrayBlockContainer>
</template>

<script>
import { computed, defineComponent, set } from 'vue'
import { useVModel } from '@vueuse/core'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import ElInputGroup from '@/components/ElInputGroup.vue'
import TitleWithSwitch from '@/components/TitleWithSwitch.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { contentActionConfigs } from '@/config/progressNotification'
import { get } from 'lodash'

export default defineComponent({
  name: 'ReservationInfoBlock',
  components: { GrayBlockContainer, ElInputWrapper, FormItemTooltipLabel, ElInputGroup, TitleWithSwitch },
  props: {
    title: String,
    notifyIndex: Number,
    contentIndex: Number,
    contentData: { type: Object, default: () => ({}) },
    contentTemplateOptions: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const syncData = useVModel(props, 'contentData', emit)
    const actionsData = computed(() => get(syncData.value, 'appointmentInfoConfig.actions'))

    const onBlockEnabledChange = (enabled) => {
      if (enabled) {
        syncData.value.appointmentInfoConfig.enabledTitle = true
        // set(syncData.value, 'appointmentInfoConfig.enabledTitle', true)
        syncData.value.appointmentInfoConfig.actions.forEach(action => { action.enabled = true })
      } else if (!enabled) {
        syncData.value.appointmentInfoConfig.enabledTitle = false
        // set(syncData.value, 'appointmentInfoConfig.enabledTitle', false)
        syncData.value.appointmentInfoConfig.actions.forEach(action => { action.enabled = false })
      }
    }

    return { syncData, contentActionConfigs, actionsData, onBlockEnabledChange }
  },
})
</script>

<style lang="postcss" scoped>
.area {
  @apply flex flex-col gap-[8px];
}
</style>
