<template>
  <el-form-item label="發送渠道" :prop="`NotifySettings[${notifyIndex}].channels`">
    <el-checkbox-group v-model="syncValue">
      <el-checkbox label="LINE">Line</el-checkbox>
      <!-- <el-checkbox label="email">Email</el-checkbox>
      <el-checkbox label="sms">簡訊</el-checkbox> -->
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
export default defineComponent({
  name: 'SendChannelOptionsBlock',
  props: {
    notifyIndex: Number,
    value: { type: Array, defualt: () => [] },
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    return { syncValue }
  },
})
</script>

<style lang="postcss" scoped>

</style>
