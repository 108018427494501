<template>
  <div class="progress-notification-setting-edit">
    <PageTitle
      :title="displayPageTitle"
      hideBtn
      icon="chevron_left"
      :fontSize="20"
      @iconClick="$router.go(-1)"
    />
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
      hide-required-asterisk
      class="flex flex-col gap-[30px]"
    >
      <TargetNotificationSelectBlock
        :value.sync="formData.NotifyTargetTemplateId"
        :targetTemplateList="targetTemplateList"
        :targetTemplate="originData?.NotifyTargetTemplate"
        :isDefault="isDefault"
      />
      <section class="wrapper">
        <p class="text-gray-60">已建立 {{ get(formData,'NotifySettings.length') }} 個發送時間，最多 {{ limitCount || '-' }} 個</p>
        <div class="flex gap-[8px]">
          <div
            v-for="(notify, idx) in notifyBlocks"
            :key="idx"
            class="block-title"
            :class="{'active-notify': focusNotifyIdx === idx}"
            @click="onChangeBlock(idx)"
          >
            通知內容 {{ idx + 1 }}
          </div>
          <AddButton v-if="get(formData,'NotifySettings.length') < limitCount" @click="addNotifySettingBlock(get(formData,'NotifySettings.length'))" />
        </div>

        <div v-show="loaded && !loading.block" v-loading="loading.block" class="content-setting-wrapper">
          <ReservationNotificationSettingEdit
            v-for="(notify, idx) in notifyBlocks"
            v-show="idx === focusNotifyIdx"
            :key="idx"
            :isDefault="isDefault"
            :notifyIndex="idx"
            :hideRemove="idx === 0"
            :form.sync="notifyBlocks[idx]"
            :targetTemplateList="targetTemplateList"
            :contentTemplateList="contentTemplateList"
            @remove="removeNotifySettingBlock(idx)"
          />
        </div>
        <div v-if="!loaded && loading.block" v-loading="loading.block" class="content-setting-wrapper" style="min-height: 500px;" />
      </section>
    </el-form>

    <PageFixedFooter
      :confirmLoading="loading.update"
      @cancel="$router.go(-1)"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, set } from 'vue'
import ReservationNotificationSettingEdit from './Reservation/ReservationNotificationSettingEdit.vue'
import TargetNotificationSelectBlock from './components/TargetNotificationSelectBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import AddButton from '@/components/Button/AddButton.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import formUtils from '@/utils/form'
import { useProgressNotificationEdit } from '@/use/useProgressNotificationEdit'
import {
  GetProgressNotificationContentTemplate,
  GetProgressNotificationTargetTemplate,
  CreateProgressNotificationSettings,
  UpdateProgressNotificationSettings,
  FindProgressNotificationSettings,
} from '@/api/progressNotification'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import router from '@/router'
import { useRoute } from 'vue-router/composables'
import { noEmptyRules, urlRules, noSameTimeRules } from '@/validation'
import { get, forEach, map, omit, includes } from 'lodash'
import { onFormRulesChangeClearValidate } from '@/use/useForm'
import notifyMessage from '@/config/notifyMessage'
import { reservationNotificationSettingTypeConfigs } from '@/config/progressNotification'
import { passValue, sortOrder } from '@/utils/helper'

export default defineComponent({
  name: 'ProgressNotificationSettingEdit',
  components: { ReservationNotificationSettingEdit, TargetNotificationSelectBlock, AddButton, PageTitle, PageFixedFooter },

  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { newNotifySettingBlock, newContentData, composeSubmitData, selectDefaultContentTemplate } = useProgressNotificationEdit()

    const route = useRoute()
    const focusNotifyIdx = ref(0)
    const notifyBlocks = ref([])
    const notifyBlockPlaceHolder = ref([newNotifySettingBlock()])
    const contentTemplateList = ref([])
    const targetTemplateList = ref([])
    const notifySettingData = ref({})
    const originData = ref({})
    const formRef = ref(null)
    const loaded = ref(false)
    const loading = reactive({
      block: true,
      update: false,
    })
    const notifyType = computed(() => route.params.type)
    const targetId = computed(() => get(route.params, 'id'))
    const isDefault = computed(() => get(notifySettingData.value, 'isDefaultAll'))
    const limitCount = computed(() => {
      const type = notifyType.value
      return get(reservationNotificationSettingTypeConfigs, `${type}.limitCount`)
    })
    const displayPageTitle = computed(() => {
      const type = notifyType.value
      if (!type) return ''
      const displayNotifyType = get(reservationNotificationSettingTypeConfigs, `${type}.label`)
      if (isDefault.value) return `預設通知 - ${displayNotifyType}`
      return `限定對象通知 - ${displayNotifyType}`
    })

    const formData = ref({
      type: notifyType.value,
      NotifyTargetTemplateId: null,
      NotifySettings: notifyBlocks.value,
    })
    const areAllElementsTheSame = (array) => {
      return array.every((value, _, array) => value === array[0])
    }
    const noSameTime = (allValues) => {
      const sendTimes = allValues.map(item => item.sendTimeSettings)

      const sendTimeOffsets = sendTimes.map(item => item.sendTimeOffset)
      const sendTimeTypes = sendTimes.map(item => item.sendTimeType)
      const sendTimeUnits = sendTimes.map(item => item.sendTimeUnit)
      const sendTimeOffsetSpecifieds = sendTimes.map(item => item.sendTimeOffsetSpecified)
      if (sendTimeUnits.every((item) => item === 'hour')) {
        if (areAllElementsTheSame(sendTimeOffsets) && areAllElementsTheSame(sendTimeTypes) && areAllElementsTheSame(sendTimeUnits)) {
          return areAllElementsTheSame(sendTimeOffsets)
        }
      } else if (sendTimeUnits.every((item) => item === 'day')) {
        if (areAllElementsTheSame(sendTimeOffsets) && areAllElementsTheSame(sendTimeTypes) && areAllElementsTheSame(sendTimeUnits)) {
          return areAllElementsTheSame(sendTimeOffsetSpecifieds)
        }
      } else return false
    }
    const formRules = computed(() => {
      const rules = {
        NotifyTargetTemplateId: [noEmptyRules()],
      }

      for (const idx in notifyBlocks.value) {
        const notifyBlockData = get(notifyBlocks.value, `[${idx}]`)
        // const notifyBlockEnabled = get(notifyBlockData, 'enabled')
        // if (notifyBlockEnabled) {
        rules[`NotifySettings[${idx}].channels`] = [noEmptyRules()]

        // send time
        const sendTimeType = get(notifyBlockData, 'sendTimeSettings.sendTimeType')
        const sendTimeUnit = get(notifyBlockData, 'sendTimeSettings.sendTimeUnit')
        const sendTimeOffset = get(notifyBlockData, 'sendTimeSettings.sendTimeOffset')
        if (sendTimeType !== 'now') {
          rules[`NotifySettings[${idx}].sendTimeSettings.sendTimeOffset`] = [noEmptyRules()]
          if (noSameTime(notifyBlocks.value) && idx > 0) {
            rules[`NotifySettings[${idx}].sendTimeSettings.sendTimeOffset`] = [noSameTimeRules()]
          }
          if (sendTimeUnit === 'day') {
            rules[`NotifySettings[${idx}].sendTimeSettings.sendTimeOffsetSpecified`] = [noEmptyRules()]
            if (noSameTime(notifyBlocks.value) && idx > 0) {
              rules[`NotifySettings[${idx}].sendTimeSettings.sendTimeOffsetSpecified`] = [noSameTimeRules()]
            }
            if (sendTimeOffset === 0) rules[`NotifySettings[${idx}].sendTimeSettings.limitScheduledMinHour`] = [noEmptyRules()]
          }
        }

        // content blocks
        for (const contentIdx in get(notifyBlockData, 'contents')) {
          const contentBlockData = get(get(notifyBlockData, 'contents'), `[${contentIdx}]`)
          const contentBlockEnabled = get(get(notifyBlockData, 'contents'), `[${contentIdx}].enabled`)
          if (contentBlockEnabled) {
            rules[`NotifySettings[${idx}].contents[${contentIdx}].NotifyContentTemplateId`] = [noEmptyRules()]
          }
          // appointmentInfo
          if (contentBlockEnabled && get(contentBlockData, 'appointmentInfoConfig')) {
            const enabledTitle = get(contentBlockData, 'appointmentInfoConfig.enabledTitle')
            if (enabledTitle) rules[`NotifySettings[${idx}].contents[${contentIdx}].appointmentInfoConfig.title`] = [noEmptyRules()]
            // actions
            if (get(contentBlockData, 'appointmentInfoConfig.actions')) {
              for (const actionIdx in get(contentBlockData, 'appointmentInfoConfig.actions')) {
                const actionData = get(contentBlockData, `appointmentInfoConfig.actions[${actionIdx}]`)
                const actionType = get(actionData, 'type')
                const actionEnabled = get(actionData, 'enabled')
                if (actionEnabled) {
                  rules[`NotifySettings[${idx}].contents[${contentIdx}].appointmentInfoConfig.actions[${actionIdx}].label`] = [noEmptyRules()]
                  if (actionType === 'openLink') rules[`NotifySettings[${idx}].contents[${contentIdx}].appointmentInfoConfig.actions[${actionIdx}].linkUrl`] = [noEmptyRules(), urlRules()]
                }
              }
            }
          }
        }
        // }
      }

      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules, 100)

    const onChangeBlock = (idx) => {
      if (focusNotifyIdx.value === idx) return
      loading.block = true
      focusNotifyIdx.value = idx
      setTimeout(() => {
        loading.block = false
      }, 30)
    }
    const addNotifySettingBlock = async (idx) => {
      await formUtils.checkForm(formRef.value)
      const prevNode = passValue(get(formData.value.NotifySettings, `[${idx - 1}]`))
      prevNode.contents = prevNode.contents.map((item, idx) => {
        // TOFIX 這邊是很髒的寫法(暫時 fix)，應該改進
        let notifyContentTemplateId = null
        if (item.type === 'imageText') {
          if (!includes([
            reservationNotificationSettingTypeConfigs.appointmentStart.value,
            reservationNotificationSettingTypeConfigs.appointmentEnd.value,
          ], notifyType.value)) {
            notifyContentTemplateId = selectDefaultContentTemplate({ templateDataList: contentTemplateList.value, contentType: item.type, notifyType: formData.value.type })
          } else {
            item.enabled = false
          }
        } else {
          notifyContentTemplateId = selectDefaultContentTemplate({ templateDataList: contentTemplateList.value, contentType: item.type, notifyType: formData.value.type })
        }

        return newContentData({ contentType: item.type, order: idx, notifyType: notifyType.value, notifyContentTemplateId, enabled: item.enabled })
      })

      console.log('🔥[debug] prevNode.contents: ', prevNode.contents)

      const newNotify = newNotifySettingBlock(omit(prevNode, ['id']))
      console.log('🔥[debug] newNotify: ', newNotify)

      notifyBlocks.value.push(newNotify)
      // notifyBlocks.value.push(newNotifySettingBlock())
      focusNotifyIdx.value = idx
    }
    const removeNotifySettingBlock = (idx) => {
      notifyBlocks.value.splice(idx, 1)
      focusNotifyIdx.value = notifyBlocks.value.length - 1
    }
    const syncFormData = (sourceData) => {
      formData.value.type = get(sourceData, 'type')
      formData.value.NotifyTargetTemplateId = get(sourceData, 'NotifyTargetTemplateId')
      for (const idx in sourceData.NotifySettings) {
        const settingData = get(sourceData.NotifySettings, `[${idx}]`)
        notifyBlocks.value.push(newNotifySettingBlock(passValue(settingData)))
      }
    }

    const onSubmit = async () => {
      loading.update = true
      const submitData = composeSubmitData(formData.value)
      const { NotifySettings } = submitData
      NotifySettings.forEach(setting => {
        const { appointmentInfoConfig } = setting.contents[0]
        if (appointmentInfoConfig && appointmentInfoConfig.actions) {
          appointmentInfoConfig.actions.forEach(action => {
            if (action.type === 'openLink' && action.enabled === false) {
              action.linkUrl = undefined
            }
          })
        }
      })
      console.log('submitData', submitData)
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        window.$message.warning(notifyMessage.formDataError)
        loading.update = false
        return
      }

      let apiMethod = CreateProgressNotificationSettings
      if (targetId.value) apiMethod = UpdateProgressNotificationSettings

      try {
        await simpleFetch(apiMethod, submitData, (res) => {
          const successMsg = targetId.value ? notifyMessage.updateSuccess : notifyMessage.createSuccess
          window.$message.success(successMsg)
          router.push({
            name: 'ReservationProgressNotificationSetting',
            params: { shopId: shopId.value },
          })
        }, true)
      } catch (error) {
        window.$message.error(error)
        loading.update = false
      }
    }

    onMounted(async () => {
      try {
        await Promise.all([
          simpleFetch(GetProgressNotificationContentTemplate, { shopId: shopId.value }, (res) => {
            contentTemplateList.value = res
          }, true),
          simpleFetch(GetProgressNotificationTargetTemplate, { shopId: shopId.value }, (res) => {
            targetTemplateList.value = res
          }, true),
        ])
        if (!targetId.value) {
          notifyBlocks.value.push(newNotifySettingBlock())
          loaded.value = true
          loading.block = false
          return
        }
        loading.block = true
        await simpleFetch(FindProgressNotificationSettings, { shopId: shopId.value, id: targetId.value }, (res) => {
          notifySettingData.value = res
          originData.value = res
          res.NotifySettings = sortOrder(res.NotifySettings, 'targetIndex')
          syncFormData({ ...res })
          loading.block = false
          loaded.value = true
        })
      } catch (error) {
        window.$message.error(error)
      }
    })

    return {
      loaded,
      loading,
      formRef,
      formRules,
      formData,
      focusNotifyIdx,
      notifyBlocks,
      onSubmit,
      addNotifySettingBlock,
      removeNotifySettingBlock,
      onChangeBlock,
      notifyBlockPlaceHolder,
      contentTemplateList,
      targetTemplateList,
      displayPageTitle,
      isDefault,
      originData,
      get,
      limitCount,
    }
  },
})
</script>

<style lang="postcss" scoped>
section {
  @apply m-0;
}

.wrapper {
  @apply flex flex-col gap-[8px];
}

.content-setting-wrapper {
  @apply bg-white p-[20px] rounded-[8px];
}

.block-title {
  @apply flex-1 border border-gray-40;
  @apply text-gray-60 text-center font-medium;
  @apply px-[24px] py-[8.5px] rounded-[8px];
  box-shadow: 0px 1px 9px 0px #010A261A;

}

.active-notify {
  @apply bg-primary-100 text-white;
}
</style>
